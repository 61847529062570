import imageScheme from "../../images/schemes/Scheme-For-C2C-Service-EN.svg";
import links from "../../utils/links";
import iconCard from "../../images/icons/styled/second/Card.svg";
import iconLightning from "../../images/icons/styled/second/Lightning.svg";
import iconFlag from "../../images/icons/styled/second/Flag.svg";
import iconWorld from "../../images/icons/styled/second/World.svg";

export default {
    "seo": {
        "title": "Payment service for C2C services | Bank 131",
        "description": "Split payments: internet acquiring for marketplaces and aggregators, splitting payments between vendors and partners"
    },
    "pageHeaderBlock": {
        "title": "Split payment </br> for C2C services",
        "text": "Split accepted online payments between individuals through the platform. Suitable for freelancer platforms and other similar services."
    },
    "stepsBlock": {
        "title": "How it works",
        "list": [
            {
                "title": 'Payment',
                "text": 'The customer makes a payment'
            },
            {
                "title": 'Split',
                "text": 'The payment is split between all the participants, and the freelancer or self-employed person receives their payment'
            },
            {
                "title": 'Tax payments',
                "text": 'The Federal Tax Agency receives the tax payment, and the platform receives their commission and a fiscal receipt'
            },
            {
                "title": 'Transparency',
                "text": 'The C2C platform can see the money change hands.',
                "image": imageScheme,
                "imageLabel": 'View diagram'
            }
        ]
    },
    "infoCardsBlock": {
        "title": "You’ll like it",
        "cardsGoTo": {
            "to": "/en/pricing",
            "text": "Pricing"
        },
        "listTitle": "Getting started",
        "list": [
            'Send a request, sharing your requirements and goals ',
            'We’ll find you a suitable solution ',
            'We’ll also need to check your documents ',
            'Next, you sign a contract',
            `And you’re all set to start <a href="${links.en.api}" target="_blank" rel="noreferrer">API integration </a>`
        ],
        "listGoTo": {
            "to": '/en/pricing',
            "text": 'Pricing'
        },
        "cards": [
            {
                "text": 'Accept online payments with Visa, MasterCard and MIR',
                "icon": iconCard ,
                "theme": 'cardSecond'
            },
            {
                "text": 'Instant payments to multiple recipients ',
                "icon": iconLightning,
                "theme": 'cardSecond'
            },
            {
                "text": 'Direct integration with the Federal Tax Agency to pay tax automatically and get a fiscal receipt',
                "icon": iconFlag,
                "theme": 'cardSecond'
            },
            {
                "text": 'For Russian and international C2C services',
                "icon": iconWorld,
                "theme": 'cardSecond'
            },
        ]
    },
    "submitApplicationBlock": {
        "title": "How to connect payments",
        "textSubmit": "Sign up",
        "textSubmitBottom": "Get in touch",
    }
}