import imageScheme from "../../images/schemes/c2c_scheme.svg";
import links from "../../utils/links";
import iconCard from "../../images/icons/styled/second/Card.svg";
import iconLightning from "../../images/icons/styled/second/Lightning.svg";
import iconFlag from "../../images/icons/styled/second/Flag.svg";
import iconWorld from "../../images/icons/styled/second/World.svg";

export default {
    "seo": {
        "title": "Интернет-эквайринг для С2С решений  | Банк 131",
        "description": "Сплит-платежи — эквайринг для маркетплейсов и агрегаторов с возможностью разделить платежи между несколькими поставщиками"
    },
    "pageHeaderBlock": {
        "title": "Мультирасчеты <br />для C2C сервисов",
        "text": "Расчёты между физическими лицами через платформу — принимайте платежи банковскими картами онлайн и распределяйте между получателями. Подходит для бирж фрилансеров и похожих сервисов."
    },
    "stepsBlock": {
        "title": "Как это работает",
        "list": [
            {
                "title": 'Платеж',
                "text": 'Покупатель оплачивает услугу'
            },
            {
                "title": 'Распределение платежа',
                "text": 'Платеж распределяется между всеми участниками: исполнитель получает оплату за услугу'
            },
            {
                "title": 'Автоматическая оплата налога',
                "text": 'Сумму налога мы напрямую перечисляем в ФНС, а С2С платформа получает комиссию и чек об оплате налога.'
            },
            {
                "title": 'Прозрачные процессы',
                "text": 'Весь процесс движения денег прозрачен для самой платформы',
                "image": imageScheme,
                "imageLabel": 'Посмотреть схему'
            }
        ]
    },
    "infoCardsBlock": {
        "title": "Вам понравится",
        "cardsGoTo": {
            "to": "/pricing",
            "text": "Тарифы"
        },
        "listTitle": "С чего начать",
        "list": [
            'Отправьте нам заявку ',
            'Мы подберём решение для вашего бизнеса ',
            'Проведём идентификацию, проверим документы ',
            'Подпишем договор',
            `Готово, можно начинать интеграцию по <a href="${links.ru.api}" target="_blank" rel="noreferrer">нашему API</a>`
        ],
        "listGoTo": {
            "to": '/pricing',
            "text": 'Тарифы'
        },
        "cards": [
            {
                "text": 'Прием платежей банковскими картами Visa, MasterCard и МИР',
                "icon": iconCard ,
                "theme": 'cardSecond'
            },
            {
                "text": 'Мгновенные выплаты нескольким получателям',
                "icon": iconLightning,
                "theme": 'cardSecond'
            },
            {
                "text": 'Прямая интеграция с ФНС для получения фискального чека и автоматической оплаты налога',
                "icon": iconFlag,
                "theme": 'cardSecond'
            },
            {
                "text": 'Юридические лица могут быть резидентами РФ или международными компаниями',
                "icon": iconWorld,
                "theme": 'cardSecond'
            },
        ]
    },
    "submitApplicationBlock": {
        "title": "Оставить заявку",
        "textSubmit": "Узнать больше"
    }
}
